import { FC, ReactNode, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as DotsIcon } from 'assets/3dots.svg';
import { ReactComponent as ThumbDownIcon } from 'assets/thumbs-down.svg';
import { ReactComponent as ThumbUpIcon } from 'assets/thumbs-up.svg';
import cn from 'classnames';
import { Tooltip } from 'components/Tooltip';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import {
  FeedbackTypes,
  InsightsType,
  InsightsTypeToReadable,
  queryKeys
} from 'enums';
import { useIsExternalUserDashboard } from 'hooks';
import { useRateChatWidget } from 'hooks/api';

import { FeedbackModal } from '../FeedbackModal';

import styles from './styles.module.scss';

interface Props {
  id: DashboardComponent['id'];
  children: ReactNode;
  type: InsightsType;
  isPaid?: boolean;
  iconClassName?: string;
  className?: string;
  hasFeedback?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
}

export const DashboardCard: FC<Props> = ({
  id,
  type,
  isPaid,
  hasFeedback,
  children,
  iconClassName,
  className
}) => {
  const { t } = useTranslation();
  const { dashboardId, messageId } = useParams();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [givenFeedback, setGivenFeedback] = useState<FeedbackTypes | null>(
    null
  );
  const open = Boolean(anchorEl);

  const {
    mutate: rateWidget,
    isPending: isRatePending,
    isSuccess: isRateSuccess
  } = useRateChatWidget(dashboardId || '');

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFeedbackModal = (feedback: FeedbackTypes | null) => {
    setGivenFeedback(feedback);
    handleClose();
  };

  const closeFeedbackModal = () => setGivenFeedback(null);

  const submitFeedback = (feedback: FeedbackTypes, feedbackText?: string) => {
    rateWidget({
      widgetId: id,
      data: {
        feedback,
        text: feedbackText,
        dashboardId: dashboardData?.id,
        widgetType: InsightsTypeToReadable[type]
      }
    });
    closeFeedbackModal();
  };

  const isRateAvailable = !hasFeedback && !isRatePending && !isRateSuccess;

  return (
    <div className={cn(styles.card, className)}>
      {!!givenFeedback && (
        <FeedbackModal
          feedback={givenFeedback}
          onClose={closeFeedbackModal}
          onSubmitFeedback={submitFeedback}
        />
      )}
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: styles.paper,
          list: styles.menu
        }}
      >
        {isPaid && (
          <Tooltip
            key={t('Page.Dashboard.RateWidget')}
            title={t('Common.FeedbackModal.DisabledTooltip')}
            disabled={!hasFeedback}
          >
            <MenuItem
              disableRipple
              className={cn(
                styles['menu-item'],
                styles.inactive,
                !isRateAvailable && styles.disabled
              )}
            >
              <>
                <span
                  data-testid="menu-option"
                  className={cn(styles['option-label'], 'overflowed-text')}
                >
                  {t('Page.Dashboard.RateWidget')}
                </span>
                <ThumbDownIcon
                  onClick={() => {
                    if (isRateAvailable) {
                      openFeedbackModal(FeedbackTypes.NEGATIVE);
                    }
                  }}
                  className={styles['thumb-icon']}
                />
                <ThumbUpIcon
                  className={styles['thumb-icon']}
                  onClick={() => {
                    if (isRateAvailable) {
                      openFeedbackModal(FeedbackTypes.POSITIVE);
                    }
                  }}
                />
              </>
            </MenuItem>
          </Tooltip>
        )}
      </Menu>
      <span
        onClick={handleClick}
        data-testid="card-more-icon"
        className={cn(
          styles['more-icon'],
          isExternalUserDashboard && styles.hidden,
          iconClassName
        )}
      >
        <DotsIcon />
      </span>
      {children}
    </div>
  );
};
