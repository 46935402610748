import { ReactNode } from 'react';
import cn from 'classnames';
import { DashboardCard } from 'components';
import { ChartUnitTypes } from 'enums';
import { InsightsType } from 'enums/InsightsType.enum';
import { DashboardComponentExtended } from 'views/DashboardV2/DashboardV2';

import {
  BigNumber,
  CRMBoosterAd,
  DashboardHistory,
  FullReportsAd,
  ManufactureProfile,
  Map,
  PieChart,
  RequestDemoCall,
  TextSummary,
  ZoomableIcicleChart,
  ZoomableSunburstChart
} from '.';
import { ZoomableTreemapChart } from './ZoomableTreemapChart';

import styles from './styles.module.scss';

export const getClassname = (
  widgetType: InsightsType,
  widgetUnit?: ChartUnitTypes
) => {
  switch (true) {
    case widgetType === InsightsType.DashboardHistory:
      return styles['dashboard-history'];
    case widgetType === InsightsType.HierarchyIndustries:
      return styles['hierarchy-industries'];
    case widgetType === InsightsType.MapDots:
      return styles['map-dots'];
    case widgetType === InsightsType.HierarchyProducts:
      return styles['hierarchy-products'];
    case widgetType === InsightsType.Treemap:
      return styles.treemap;
    case widgetType === InsightsType.CRMBoosterAd:
      return styles['crm-booster'];
    case widgetType === InsightsType.ManufacturerProfiles:
      return styles['manufacturer-profiles'];
    case widgetType === InsightsType.BigNumber &&
      widgetUnit === ChartUnitTypes.CompaniesCount:
      return styles['big-number-manufacturers'];
    case widgetType === InsightsType.BigNumber &&
      widgetUnit === ChartUnitTypes.EmployeeCount:
      return styles['big-number-machines'];
    case widgetType === InsightsType.TextSummary:
      return styles['chatbot-answer'];
    case widgetType === InsightsType.PieChart &&
      widgetUnit === ChartUnitTypes.MaterialsPie:
      return styles['pie-chart-materials'];
    case widgetType === InsightsType.PieChart &&
      widgetUnit === ChartUnitTypes.TechnologiesPie:
      return styles['pie-chart-technologies'];
    case widgetType === InsightsType.ReportAd:
      return styles['report-ad'];
    case widgetType === InsightsType.RequestDemoCall:
      return styles['request-demo-call'];
    default:
      break;
  }
};

export const getDashboardWidget = ({
  title,
  note,
  isPaid,
  widget,
  summary,
  isTextWidgetsProcessing
}: {
  title?: string;
  note?: string;
  isPaid?: boolean;
  summary?: string;
  isTextWidgetsProcessing?: boolean;
  widget: DashboardComponentExtended;
}) => {
  const curComp: {
    data: ReactNode;
    className?: string;
    iconClassName?: string;
  } = {
    className: '',
    iconClassName: '',
    data: <div />
  };

  if (widget.type === InsightsType.PieChart) {
    curComp.data = (
      <PieChart
        note={note}
        title={title}
        summary={summary}
        data={widget.params}
        isTextWidgetsProcessing={isTextWidgetsProcessing}
      />
    );
  } else if (widget.type === InsightsType.HierarchyProducts) {
    curComp.data = (
      <ZoomableIcicleChart
        title={title}
        summary={summary}
        data={widget.params}
        isTextWidgetsProcessing={isTextWidgetsProcessing}
      />
    );
  } else if (widget.type === InsightsType.HierarchyIndustries) {
    curComp.className = styles['hierarchy-industries-card'];
    curComp.data = <ZoomableSunburstChart data={widget.params} />;
  } else if (widget.type === InsightsType.Treemap) {
    curComp.data = (
      <ZoomableTreemapChart
        title={title}
        summary={summary}
        data={widget.params}
        widgetType={widget.type}
        isTextWidgetsProcessing={isTextWidgetsProcessing}
      />
    );
  } else if (widget.type === InsightsType.TextSummary) {
    curComp.iconClassName = styles.hidden;
    curComp.data = (
      <TextSummary
        data={widget?.params}
        isTextWidgetsProcessing={isTextWidgetsProcessing}
      />
    );
  } else if (widget.type === InsightsType.CRMBoosterAd) {
    curComp.iconClassName = styles.hidden;
    curComp.data = <CRMBoosterAd />;
  } else if (widget.type === InsightsType.ManufacturerProfiles) {
    curComp.className = styles['no-padding'];
    curComp.data = <ManufactureProfile data={widget.params} title={title} />;
  } else if (widget.type === InsightsType.DashboardHistory) {
    curComp.className = cn(styles['dashboard-history'], styles['no-padding']);
    curComp.iconClassName = styles.hidden;
    curComp.data = <DashboardHistory data={widget.params} />;
  } else if (widget.type === InsightsType.BigNumber) {
    curComp.data = <BigNumber data={widget.params} title={title} />;
  } else if (
    widget.type === InsightsType.MapDots &&
    widget?.params?.data?.[0].value?.length
  ) {
    curComp.data = <Map data={widget} title={title} />;
  } else if (widget.type === InsightsType.ReportAd) {
    curComp.iconClassName = styles.hidden;
    curComp.data = <FullReportsAd />;
  } else if (widget.type === InsightsType.RequestDemoCall) {
    curComp.className = styles['auto-height'];
    curComp.iconClassName = styles.hidden;
    curComp.data = <RequestDemoCall />;
  } else {
    curComp.data = null;
  }

  return curComp.data ? (
    <div
      key={widget.id}
      className={cn(styles.card, styles[widget.type], widget.className)}
    >
      <DashboardCard
        id={widget.id}
        isPaid={isPaid}
        type={widget.type}
        hasFeedback={widget.hasFeedback}
        iconClassName={curComp.iconClassName}
        className={cn(curComp.className, styles['widget-card'])}
      >
        {curComp.data}
      </DashboardCard>
    </div>
  ) : null;
};
