import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal } from 'components';
import { useSnackbar } from 'hooks';
import { useUserInfo } from 'hooks/api';
import { useRequestProductsData } from 'hooks/api/useRequestProductsData';

import { MoreDataSuccessModal } from './MoreDataSuccessModal';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  defaultSelectedProducts?: string[];
  onSubmitSelection?: (selectedProducts: string) => void;
}

const chips = [
  'Common.MoreDataModal.Products.CompetitorAnalysis',
  'Common.MoreDataModal.Products.CRMBooster',
  'Common.MoreDataModal.Products.MarketAnalysis',
  'Common.MoreDataModal.Products.TrendAnalysis',
  'Common.MoreDataModal.Products.RiskAnalysis',
  'Common.MoreDataModal.Products.CustomDatabase',
  'Common.MoreDataModal.Products.TechnologyComparison'
];

export const MoreDataModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmitSelection,
  defaultSelectedProducts = []
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    defaultSelectedProducts
  );

  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const { data: userInfo } = useUserInfo();
  const { mutate: requestProductsData } = useRequestProductsData();

  const toggleSuccessModal = useCallback(() => {
    setIsSuccessModalOpen((prevState) => !prevState);
  }, []);

  const automaticallyToggleSuccessModal = () => {
    setIsSuccessModalOpen(true);
    setTimeout(() => setIsSuccessModalOpen(false), 2000);
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setInputValue(event.target.value);

  const handleClose = () => {
    onClose();
    setInputValue('');
    setSelectedProducts(defaultSelectedProducts);
  };

  const onSuccess = () => {
    if (onSubmitSelection) {
      onSubmitSelection(selectedProducts.join(', '));
    }

    automaticallyToggleSuccessModal();
    handleClose();
  };

  useEffect(() => {
    if (!document.getElementById('recaptcha-script')) {
      const script = document.createElement('script');

      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
      script.id = 'recaptcha-script';

      document.body.appendChild(script);
    }
  }, []);

  const getError = useCallback(
    (submitted: boolean) => {
      if (!inputValue || !submitted) {
        return '';
      }

      if (inputValue.length < 10) {
        return t('Common.MoreDataModal.AdditionalInformationMinError');
      }

      return '';
    },
    [inputValue, t]
  );

  const handleSubmit = () => {
    setIsSubmited(true);

    if (getError(true)) {
      return;
    }

    if (!selectedProducts.length) {
      snackbar.warning.commonWarning(
        t('Common.MoreDataModal.SelectProductWarning')
      );

      return;
    }

    if (!userInfo) {
      return;
    }

    // @ts-expect-error undeclared variable
    if (grecaptcha) {
      // @ts-expect-error undeclared variable
      grecaptcha.ready(async () => {
        // @ts-expect-error undeclared variable
        const token = await grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY,
          { action: 'submit' }
        );

        const { firstName, lastName, email, preferredLanguage } = userInfo;

        const formData = new FormData();

        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('preferences', selectedProducts.join(', '));
        formData.append('additionalInfo', inputValue);
        formData.append('lang', preferredLanguage);
        formData.append('g-recaptcha-response', token);

        const body = new URLSearchParams(formData as any);

        requestProductsData({
          payload: body,
          onSuccess
        });
      });
    }
  };

  const onChipClick = (product: string) => {
    const products = selectedProducts.includes(product)
      ? selectedProducts.filter(
          (selectedProduct) => selectedProduct !== product
        )
      : [...selectedProducts, product];
    setSelectedProducts(products);
  };

  return (
    <>
      <MoreDataSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={toggleSuccessModal}
      />
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        titleClassName={styles.title}
        title={t('Common.MoreDataModal.Title')}
      >
        <p className={styles.subtitle}>{t('Common.MoreDataModal.Subtitle')}</p>

        <div className={styles['main-content']}>
          <div className={styles.chips}>
            {chips.map((chip) => (
              <div
                key={chip}
                className={cn(
                  styles.chip,
                  selectedProducts.includes(t(chip)) && styles.selected
                )}
                onClick={() => onChipClick(t(chip))}
              >
                {t(chip)}
              </div>
            ))}
          </div>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Common.MoreDataModal.AdditionalInformation')}
            </span>
            <TextareaAutosize
              minRows={2}
              maxLength={300}
              value={inputValue}
              onChange={onChange}
              className={styles.container}
              placeholder={t(
                'Common.MoreDataModal.AdditionalInformationPlaceholder'
              )}
            />
            {!!getError(isSubmited) && (
              <p className={styles.error}>{getError(isSubmited)}</p>
            )}
          </div>
        </div>

        <div className={styles.buttons}>
          <Button
            size="medium"
            type="submit"
            color="primary"
            variant="outlined"
            className={cn(styles.button, styles.cancel)}
            onClick={handleClose}
          >
            {t('Common.MoreDataModal.Cancel')}
          </Button>
          <Button
            size="medium"
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={cn(styles.button, styles.submit)}
          >
            {t('Common.MoreDataModal.Next')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
