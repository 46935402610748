import { FC } from 'react';
import { useChatAnnouncement } from 'hooks/api/useChatAnnouncement';

import styles from './styles.module.scss';

interface Props {
  isActive: boolean;
}

export const AnnouncementBar: FC<Props> = ({ isActive }) => {
  const { data } = useChatAnnouncement();
  const now = new Date();
  const isExpired = data?.dueDate ? now >= new Date(data.dueDate) : true;

  return data?.body && isActive && !isExpired ? (
    <div
      className={styles.container}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: data?.body }}
    />
  ) : null;
};
