import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { useIsExternalUserDashboard } from 'hooks';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

const calendarURL =
  'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1neZJbfcocpkljEJXfFZGEInujdBbzxFAqcZb5Dck0DZHiMlcoLDAHwCNv3HiMH-xqHnb26pyf?gv=true';
export const RequestDemoCall = () => {
  const { t } = useTranslation();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { dashboardId, chatId } = useParams();

  const onClick = () => {
    mixpanel?.track(MixpanelEvents.ContactGieniClick, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': `${location.origin}/chats/${chatId}/dashboards/${dashboardId}`
    });

    window.open(calendarURL, '_blank');
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h2 className={styles.title}>
            {t('Page.Dashboard.RequestDemoCall.Title')}
          </h2>
          <span className={styles.subtitle}>
            {t('Page.Dashboard.RequestDemoCall.Subtitle')}
          </span>
        </div>

        <Button
          size="medium"
          color="primary"
          onClick={onClick}
          variant="contained"
          disabled={isExternalUserDashboard}
          className={cn(styles.cta, 'brilliance')}
        >
          {t('Page.Dashboard.RequestDemoCall.Cta')}
        </Button>
      </div>
    </>
  );
};
