import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  createNestedDashboard,
  fetchNestedDashboards
} from 'actions/dashboardActions';
import { ApiError } from 'entities/ApiError.entity';
import { queryKeys, Routes } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';
import { NestedDashboardPayload } from 'services/API/Dashboard/DashboardApi';

export const useCreatedNestedDashboard = (onSuccess: () => void) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return useMutation<
    { dashboardId: string },
    ApiError,
    {
      data: NestedDashboardPayload;
      chatId: string;
      dashboardId: string;
      originDashboardId?: string;
    }
  >({
    mutationFn: ({ data, dashboardId }) =>
      createNestedDashboard({ dashboardId, data }),
    onSuccess(newDashboard, { chatId, originDashboardId }) {
      navigate(
        Routes.Dashboard.replace(
          ':dashboardId',
          newDashboard.dashboardId
        ).replace(':chatId', chatId)
      );
      onSuccess();

      if (originDashboardId) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.nestedDashboards(originDashboardId)
        });
        queryClient.fetchQuery({
          queryKey: queryKeys.nestedDashboards(originDashboardId),
          queryFn: () => fetchNestedDashboards(originDashboardId)
        });
      }
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
