import { FC, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Message } from 'entities/Message.entity';
import { MessageTypes } from 'enums/MessageTypes.enum';

import { ChatMessage } from './ChatMessage';

import styles from './styles.module.scss';

interface Props {
  messages: Message[];
  isLoading?: boolean;
  isMessageSending?: boolean;
  isRateButtonHidden?: boolean;
  isDashboardButtonHidden?: boolean;
  isRegenerateMessageSending?: boolean;
  onRequestMoreDataClick?: () => void;
  onSuggestionClick?: (text: string) => void;
  regenerateChatMessage?: (messageId: string) => void;
  onShowDashboardClick?: (dashboardId: string, isPaid: boolean) => void;
}

export const Chat: FC<Props> = ({
  messages,
  isLoading,
  isMessageSending,
  isDashboardButtonHidden,
  isRateButtonHidden,
  isRegenerateMessageSending,
  regenerateChatMessage,
  onShowDashboardClick,
  onSuggestionClick,
  onRequestMoreDataClick
}) => {
  const listRef = useRef<HTMLDivElement>(null);

  const scrollToBottomOnLoading = useCallback(() => {
    if (isLoading && listRef?.current) {
      listRef.current.scrollTop = listRef?.current?.scrollHeight;
    }
  }, [isLoading]);

  useEffect(() => {
    scrollToBottomOnLoading();
  }, [scrollToBottomOnLoading]);

  return (
    <div className={cn(styles.chat)}>
      <div ref={listRef} className={styles.list}>
        {messages?.map((message, index) => {
          const isLastMessage = index === 0;
          const isLastAnswer =
            isLastMessage &&
            (message.type === MessageTypes.answer ||
              message.type === MessageTypes.followup_questions);
          const key = `${message.id}-${message.type}-${message.messageId}-${index}`;

          return (
            <ChatMessage
              key={key}
              data={message}
              isLoading={isLoading}
              isLastAnswer={isLastAnswer}
              isMessageSending={isMessageSending}
              onSuggestionClick={onSuggestionClick}
              isRateButtonHidden={isRateButtonHidden}
              onShowDashboardClick={onShowDashboardClick}
              regenerateChatMessage={regenerateChatMessage}
              onRequestMoreDataClick={onRequestMoreDataClick}
              isDashboardButtonHidden={isDashboardButtonHidden}
              isRegenerateMessageSending={isRegenerateMessageSending}
            />
          );
        })}
      </div>
    </div>
  );
};
