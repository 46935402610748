import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as CrossIconOutlined } from 'assets/CrossIconOutlined.svg';
import { ReactComponent as ReleaseNotesIcon } from 'assets/release-notes.svg';
import { Routes } from 'enums';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { LocalStorage } from 'services/LocalStorage';
import { SHOW_NEW_DEVELOPMENTS_TOAST } from 'utils/constants';

import styles from './styles.module.scss';

const restrictedRoutes = [
  Routes.Auth,
  Routes.Login,
  Routes.Confirm,
  Routes.Checkout,
  Routes.VerifyUser,
  Routes.Registration,
  Routes.NewDevelopments,
  Routes.SubscriptionConfirm,
  Routes.CoinsPaymentConfirm,
  Routes.OneTimePaymentCheckout,
  Routes.RestorePassword,
  Routes.ForgotPassword
];

export const NewDevelopmentsToast = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const { data: userInfo } = useUserInfo();

  useEffect(() => {
    if (userInfo) {
      if (userInfo?.showReleaseNotes) {
        const showNewDevelopmentsToast = LocalStorage.getItem(
          SHOW_NEW_DEVELOPMENTS_TOAST
        ) as boolean | null;

        if (showNewDevelopmentsToast === null) {
          LocalStorage.setItem(SHOW_NEW_DEVELOPMENTS_TOAST, true);
          setIsVisible(true);
        } else {
          setIsVisible(showNewDevelopmentsToast);
        }
      } else {
        LocalStorage.removeItem(SHOW_NEW_DEVELOPMENTS_TOAST);
        setIsVisible(false);
      }
    }
  }, [userInfo]);

  const handleCloseToast = () => {
    setIsVisible(false);
    LocalStorage.setItem(SHOW_NEW_DEVELOPMENTS_TOAST, false);
  };

  const handleButtonClick = () => {
    navigate(Routes.NewDevelopments);
    handleCloseToast();
  };

  return isVisible &&
    !restrictedRoutes.includes(location.pathname as Routes) ? (
    <div className={styles['toast-wrapper']}>
      <div className={styles.toast}>
        <div className={styles.heading}>
          <ReleaseNotesIcon className={styles.icon} />
          <CrossIconOutlined
            className={styles.close}
            onClick={handleCloseToast}
          />
        </div>
        <span className={styles.title}>
          {t('Common.NewDevelopmentsToast.Title')}
        </span>
        <span className={styles.subtitle}>
          {t('Common.NewDevelopmentsToast.Subtitle')}
        </span>
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="outlined"
          className={styles.button}
          onClick={handleButtonClick}
        >
          {t('Common.NewDevelopmentsToast.Button')}
        </Button>
      </div>
    </div>
  ) : null;
};
