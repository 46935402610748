import mixpanel from 'mixpanel-browser';

const shouldInitialize = process.env.NODE_ENV === 'production';

if (shouldInitialize && process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    persistence: 'localStorage'
  });
}

export enum MixpanelEvents {
  Signin = 'Signin',
  Signup = 'Signup',
  Logout = 'Logout',
  AuthWithGoogle = 'Auth With Google',
  UpdateUserInfo = 'Update User Info',
  AccountActivation = 'Account activation',
  AccountDeactivation = 'Account deactivation',
  ToggleStatusChanged = 'Toggle Status Changed',
  ResendConfirmationEmail = 'Resend Confirmation Email',
  AnswerFeedback = 'Answer Feedback',
  WidgetFeedback = 'Widget Feedback',
  AccountCreated = 'Account Created',
  AdWidgetClick = 'Ad-Widget Click',
  SuggestionClick = 'General Suggestion Clicked',
  QuestionAsked = 'Question Asked',
  DashboardCreated = 'Dashboard Created',
  AdditionalDataRequested = 'Additional Data Requested from Chat',
  CRMBoosterRequested = 'CRM Booster Requested from Dashboard',
  ContactGieniClick = 'Contact Gieni Clicked',
  DatalakeRequested = 'Datalake Requested from Dashboard',
  NewChatClick = 'Create new chat Clicked',
  FilterClick = 'Filter Clicked',
  ConfirmFilterClick = 'Confirm Filter Clicked',
  CancelFilterClick = 'Cancel Filter Clicked',
  GoPricingClick = 'Go to Pricing Clicked'
}

export default shouldInitialize ? mixpanel : null;
