import { MessageTypes } from 'enums/MessageTypes.enum';
import { date, deserialize, serializable } from 'serializr';

export class Message {
  @serializable
  id?: string | undefined = undefined;

  @serializable
  userId?: string = '';

  @serializable
  chatId?: string = '';

  @serializable
  messageId?: string | undefined = undefined;

  @serializable
  isPaid?: boolean | null = null;

  @serializable
  hasFeedback?: boolean | null = null;

  // TODO: Change to Coin on BE, not sure if it's needed at all
  @serializable
  isCreditUsed?: boolean = false;

  @serializable
  isNotFull?: boolean = false;

  @serializable
  dashboardId?: string | null = null;

  @serializable
  type: MessageTypes = MessageTypes.question;

  @serializable
  statement: string | null = '';

  @serializable(date())
  createdAt?: Date = new Date();

  @serializable
  isInterrupted?: boolean = false;

  @serializable
  isCompanyListLimited?: boolean | null = null;

  @serializable
  hasUpgradeButton?: boolean = false;

  @serializable
  suggestions?: string[];

  @serializable
  requestMoreDataVisible?: boolean = true;

  static deserialize(json: Object | string): Message {
    return deserialize(Message, json);
  }
}
