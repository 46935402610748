/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MapGl, {
  type CircleLayer,
  HeatmapLayer,
  Layer,
  LngLatLike,
  type MapRef,
  NavigationControl,
  Projection,
  Source,
  SymbolLayer
} from 'react-map-gl';
import { useParams } from 'react-router-dom';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactComponent as ChevronIcon } from 'assets/ChevronIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { Tooltip, WidgetEmptyState } from 'components';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { MapTypes } from 'enums';
import type { Feature, FeatureCollection } from 'geojson';
import { useIsExternalUserDashboard } from 'hooks';
import { useUpdateWidgetFilter } from 'hooks/api';
import { shortifyNumber } from 'utils/numberUtils';

import { FoundationYearFilter } from './FoundationYearFilter';
import otherMapData from './otherMapData.json';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  data: DashboardComponent;
}

interface MapSizes {
  width: number;
  height: number;
}

interface MapBoundaries {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
}

enum HeatmapColors {
  HighDensity = '#E95A5A',
  MediumHighDensity = '#FFA51C',
  MediumLowDensity = '#00D878',
  LowDensity = '#7DEAB2'
}

const DOT_COLORS = ['#f57a00', '#00aa49', '#C34CC6', '#676767'];
const MAX_GROUPS_COUNT = 3;
const MAX_DOTS_AMOUNT_PER_GROUP = 5000;
const DEFAULT_CLUSTER_LAYER_ID = 'clusters-layer-0';
const DEFAULT_CLUSTER_SOURCE_ID = 'clusters-source-0';
const MAP_PROJECTION: Projection = { name: 'mercator' };

export const Map: FC<Props> = ({ title, data }) => {
  const { filters, params } = data;

  const { dashboardId } = useParams();
  const { t } = useTranslation();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const mapRef = useRef<MapRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [mapType, setMapType] = useState<MapTypes>(MapTypes.Dots);
  const [mapSizes, setMapSizes] = useState<MapSizes | null>(null);
  const [groupFilter, setGroupFilter] = useState<string[]>([]);
  const [activeYear, setActiveYear] = useState<number | null>(null);
  const [mapBoundaries, setMapBoundaries] = useState<MapBoundaries | null>(
    null
  );

  const { mutate: updateWidgetsFiltersMutation } = useUpdateWidgetFilter({
    dashboardId: dashboardId || '',
    widgetId: data.id
  });

  const MAP_TYPES = [
    {
      label: t('Page.Dashboard.Map.Maptype.Dot'),
      value: MapTypes.Dots
    },
    {
      label: t('Page.Dashboard.Map.Maptype.Heat'),
      value: MapTypes.Heat
    },
    {
      label: t('Page.Dashboard.Map.Maptype.Cluster'),
      value: MapTypes.Cluster
    }
  ];

  const otherDataGroup = {
    name: 'All',
    label: t('Page.Dashboard.Map.AllGroupLabel'),
    value: otherMapData as any as [number, number, number][],
    isOther: true
  };

  const dataGroups = useMemo(() => {
    const groups: Array<DashboardParamsData & { isOther?: boolean }> = [
      ...(data.params?.data || []).slice(0, MAX_GROUPS_COUNT)
    ];

    if (mapType === MapTypes.Dots) {
      groups.push(otherDataGroup);
    }

    return groups;
  }, [data.params?.data, mapType]);

  const { startYear, endYear, foundationsByYear, isFilterVisible } =
    useMemo(() => {
      const isDotsMap = mapType === MapTypes.Dots;

      const filteredDataGroups = dataGroups.filter((group) => {
        const isInFilter =
          filters?.length && filters?.includes(group.name || group.label);

        if (!isDotsMap) {
          return !group.isOther && isInFilter;
        }

        return isInFilter;
      });

      const foundationYearsArray = filteredDataGroups
        .reduce(
          (acc, currentGroup) => {
            return acc.concat(currentGroup.value.map((value) => value[2]));
          },
          [] as Array<number | undefined>
        )
        .filter((year) => year !== undefined) as number[];

      const foundationYears = Array.from(
        new Set(foundationYearsArray)
      ) as number[];

      const startYear = Math.min(...foundationYears);
      const endYear = Math.max(...foundationYears);

      const foundationsByYear: { [key: number]: number } = {};

      for (let year = startYear; year <= endYear; year += 1) {
        foundationsByYear[year] = 0;
      }

      foundationYearsArray.forEach((year) => {
        if (year in foundationsByYear) {
          foundationsByYear[year] += 1;
        }
      });

      if (!activeYear) {
        setActiveYear(endYear);
      }

      return {
        endYear,
        startYear,
        isFilterVisible: !!foundationYears.length,
        foundationsByYear
      };
    }, [dataGroups, filters]);

  const resizeMap = () => {
    setTimeout(() => {
      if (containerRef?.current && descriptionRef?.current) {
        setMapSizes({
          width: containerRef.current.offsetWidth,
          height:
            containerRef.current.offsetHeight -
            descriptionRef.current.offsetHeight
        });
      }
    }, 150);
  };

  useEffect(() => {
    if (filters?.length) {
      setGroupFilter(filters);
    }
  }, []);

  useEffect(() => {
    if (data?.mapType) {
      setMapType(data?.mapType);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeMap);

    return () => {
      window.removeEventListener('resize', resizeMap);
    };
  }, []);

  const getMapBoundaries = () => {
    const coordinates = (data?.params?.data as DashboardParamsData[]).reduce(
      (result: Array<[number, number, number?, number?]>, { value }) => [
        ...result,
        ...value
      ],
      []
    );
    const minLatitude = Math.min(...coordinates.map((c) => c[0]));
    const maxLatitude = Math.max(...coordinates.map((c) => c[0]));
    const minLongitude = Math.min(...coordinates.map((c) => c[1]));
    const maxLongitude = Math.max(...coordinates.map((c) => c[1]));

    setMapBoundaries({
      minLatitude,
      maxLatitude,
      minLongitude,
      maxLongitude
    });
  };

  useEffect(() => {
    if (data.params?.data?.length && !mapBoundaries) {
      if (window.Worker) {
        const mapWorker = new Worker('/workers/mapWorker.js');

        mapWorker.onmessage = (event: MessageEvent) => {
          setMapBoundaries({ ...(event.data as MapBoundaries) });

          mapWorker.terminate();
        };

        mapWorker.postMessage(data.params.data);
      } else {
        getMapBoundaries();
      }
    }

    return () => {
      setMapBoundaries(null);
    };
  }, []);

  useEffect(() => {
    // HINT: It's a hack to avoid issues with setting proper size for map canvas while react-grid-layout is initializing.
    setTimeout(resizeMap, 500);
  }, [mapBoundaries]);

  useEffect(() => {
    mapRef?.current?.resize();
  }, [mapSizes?.width, mapSizes?.height]);

  const fitToBounds = () => {
    if (mapRef?.current && mapBoundaries) {
      mapRef?.current?.fitBounds(
        [
          [mapBoundaries.minLongitude, mapBoundaries.minLatitude],
          [mapBoundaries.maxLongitude, mapBoundaries.maxLatitude]
        ],
        { padding: 40, duration: 1000 }
      );
    }
  };

  useEffect(() => {
    fitToBounds();
  }, [mapBoundaries, mapSizes]);

  const handleLegendItemClick = (label: string, hidden: boolean) => () => {
    let newFilters: string[] = [];

    if (hidden) {
      newFilters = [...groupFilter, label];
    } else {
      newFilters = groupFilter.filter((item) => item !== label);
    }

    setGroupFilter(newFilters);

    if (isExternalUserDashboard) {
      return;
    }

    updateWidgetsFiltersMutation({ filters: newFilters, mapType });
  };

  const renderMapLayer = ({
    index,
    beforeId,
    layerData,
    sourceData,
    sourceOptions = {}
  }: {
    index?: number;
    beforeId?: number;
    layerData: CircleLayer | HeatmapLayer | SymbolLayer;
    sourceData: FeatureCollection;
    sourceOptions?: {
      id?: string;
      cluster?: boolean;
      clusterMaxZoom?: number;
      clusterRadius?: number;
    };
  }) => (
    <Source
      // eslint-disable-next-line react/no-array-index-key
      key={`${mapType}-layer-${index || 0}`}
      type="geojson"
      data={sourceData}
      {...sourceOptions}
    >
      <Layer
        beforeId={
          index && beforeId != null ? `${mapType}-layer-${beforeId}` : undefined
        }
        {...layerData}
      />
    </Source>
  );

  const renderDotsData = () => {
    let beforeId = 0;

    return dataGroups.map(({ value, isOther, label, name }, index) => {
      if (!value.length) return null;

      const layerData: CircleLayer = {
        id: `${mapType}-layer-${index}`,
        type: 'circle',
        paint: {
          'circle-radius': {
            base: 1,
            stops: [
              [6, 2],
              [10, 4]
            ]
          },
          'circle-color': isOther
            ? DOT_COLORS[DOT_COLORS.length - 1]
            : DOT_COLORS[index]
        }
      };

      const sourceData: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
      };

      if (groupFilter.includes(isOther ? name : label)) {
        value
          .filter(
            (item) => item[2] !== undefined && item[2] <= Number(activeYear)
          )
          .forEach((coordinates) => {
            sourceData.features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [coordinates[1], coordinates[0]]
              }
            } as Feature);
          });
      }

      if (index) beforeId = index - 1;

      return renderMapLayer({ layerData, sourceData, index, beforeId });
    });
  };

  const renderHeatData = () => {
    const layerData: HeatmapLayer = {
      id: `heat-layer-0`,
      type: 'heatmap',
      paint: {
        'heatmap-weight': {
          type: 'identity',
          property: 'point_count'
        },
        'heatmap-intensity': 0.5,
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(181, 242, 208, 0)',
          0.2,
          HeatmapColors.LowDensity,
          0.4,
          HeatmapColors.MediumLowDensity,
          0.5,
          HeatmapColors.MediumHighDensity,
          1,
          HeatmapColors.HighDensity
        ],
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20]
      }
    };

    const sourceData: FeatureCollection = {
      type: 'FeatureCollection',
      features: []
    };

    dataGroups.forEach(({ value, label, name, isOther }) => {
      if (groupFilter.includes(isOther ? name : label)) {
        value
          .filter(
            (item) =>
              isOther ||
              (item[2] !== undefined && item[2] <= Number(activeYear))
          )
          .forEach((coordinates) => {
            sourceData.features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [coordinates[1], coordinates[0]]
              }
            } as Feature);
          });
      }
    });

    return renderMapLayer({ layerData, sourceData });
  };

  const renderClusterData = () => {
    const clustersLayerData: CircleLayer = {
      id: DEFAULT_CLUSTER_LAYER_ID,
      type: 'circle',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#B5F2D0',
          100,
          '#00BE56',
          750,
          '#FB8A00'
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
      }
    };

    const countLayerData: SymbolLayer = {
      id: 'cluster-layer-1',
      type: 'symbol',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    };

    const unclusteredLayerData: CircleLayer = {
      id: 'cluster-layer-2',
      type: 'circle',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#00BE56',
        'circle-radius': 3
      }
    };

    const sourceData: FeatureCollection = {
      type: 'FeatureCollection',
      features: []
    };

    dataGroups.forEach(({ value, label, name, isOther }) => {
      if (groupFilter.includes(isOther ? name : label)) {
        value
          .filter(
            (item) =>
              isOther ||
              (item[2] !== undefined && item[2] <= Number(activeYear))
          )
          .forEach((coordinates) => {
            sourceData.features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [coordinates[1], coordinates[0]]
              }
            } as Feature);
          });
      }
    });

    const sourceOptions = {
      id: DEFAULT_CLUSTER_SOURCE_ID,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50
    };

    const layers = [clustersLayerData, countLayerData, unclusteredLayerData];

    return layers.map((layerData, index) =>
      renderMapLayer({ index, layerData, sourceData, sourceOptions })
    );
  };

  const mapDataVisualisation = useMemo(() => {
    switch (mapType) {
      case MapTypes.Dots:
        return renderDotsData();
      case MapTypes.Heat:
        return renderHeatData();
      case MapTypes.Cluster:
        return renderClusterData();
      default:
        return renderDotsData();
    }
  }, [mapType, data.params?.data, groupFilter, activeYear]);

  const renderMapLegend = () => (
    <div className={cn(styles.legend)}>
      {dataGroups.map(({ label, name, isOther }, index) => {
        const filterName = isOther ? name : label;
        const hidden = !groupFilter.includes(filterName);

        return (
          <span
            key={label}
            onClick={handleLegendItemClick(filterName, hidden)}
            className={cn(
              styles['legend-item'],
              styles[`legend-item-${index + 1}`],
              isOther && styles['legend-item-other'],
              hidden && styles.hidden
            )}
          >
            {label}
          </span>
        );
      })}
    </div>
  );

  const handleMapTypeChange = (event: SelectChangeEvent<MapTypes>) => {
    setMapType(event.target.value as MapTypes);

    if (isExternalUserDashboard) {
      return;
    }

    updateWidgetsFiltersMutation({
      filters: groupFilter,
      mapType: event.target.value as MapTypes
    });
  };

  const handleActiveYearChange = (e: ChangeEvent<HTMLInputElement>) =>
    setActiveYear(+e.target.value);

  const renderMapTypeSelect = () => (
    <Select
      size="small"
      value={mapType}
      IconComponent={ChevronIcon}
      className={styles['map-type-select']}
      onChange={handleMapTypeChange}
      MenuProps={{
        className: styles['map-type-select-menu'],
        transitionDuration: 0
      }}
    >
      {MAP_TYPES.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );

  const renderHeatMapLegend = () =>
    mapType === MapTypes.Heat && (
      <div className={styles['heatmap-legend']}>
        <span className={styles['heatmap-legend-description']}>
          {t('Page.Dashboard.Map.HeatmapLegend.HighDensity')}
        </span>
        <div className={styles['heatmap-legend-scale']} />
        <span className={styles['heatmap-legend-description']}>
          {t('Page.Dashboard.Map.HeatmapLegend.LowDensity')}
        </span>
      </div>
    );

  const setClusterLayerHandlers = () => {
    mapRef?.current?.on('click', DEFAULT_CLUSTER_LAYER_ID, (e) => {
      if (mapRef?.current) {
        const features = mapRef.current.queryRenderedFeatures(e.point, {
          layers: [DEFAULT_CLUSTER_LAYER_ID]
        });

        if (features?.length && features[0] != null) {
          const feature = features[0];
          const clusterId = feature?.properties?.cluster_id;
          const source = mapRef.current.getSource(
            DEFAULT_CLUSTER_SOURCE_ID
          ) as mapboxgl.GeoJSONSource;

          if (clusterId != null) {
            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              const center = (feature.geometry as GeoJSON.Point)
                .coordinates as LngLatLike;

              mapRef?.current?.easeTo({
                zoom,
                center
              });
            });
          }
        }
      }
    });

    mapRef?.current?.on('mouseenter', DEFAULT_CLUSTER_LAYER_ID, () => {
      const canvas = mapRef?.current?.getCanvas();

      if (canvas) {
        canvas.style.cursor = 'pointer';
      }
    });

    mapRef?.current?.on('mouseleave', DEFAULT_CLUSTER_LAYER_ID, () => {
      const canvas = mapRef?.current?.getCanvas();

      if (canvas) {
        canvas.style.cursor = '';
      }
    });
  };

  const handleMapLoad = () => {
    fitToBounds();
    setClusterLayerHandlers();
  };

  const maxPossibleDotsAmount =
    MAX_DOTS_AMOUNT_PER_GROUP * (params?.data?.length || 1);

  return (
    <div ref={containerRef} className={styles.container}>
      <div ref={descriptionRef} className={styles.header}>
        <p className={cn(styles.title, 'overflowed-text-multiline')}>
          {title || t('Page.Dashboard.Map.Title')}
        </p>

        {params?.displayed_amount &&
          params?.displayed_amount >= maxPossibleDotsAmount && (
            <Tooltip
              title={t('Page.Dashboard.Map.MaxVisibleAmountTooltip', {
                limitAmount: maxPossibleDotsAmount,
                totalAmount: shortifyNumber(params?.max_amount || 0)
              })}
            >
              <InfoIcon />
            </Tooltip>
          )}
      </div>

      {params?.data?.length ? (
        <div className={styles.map}>
          <MapGl
            ref={mapRef}
            scrollZoom={false}
            dragRotate={false}
            style={{ ...mapSizes }}
            onLoad={handleMapLoad}
            mapStyle="mapbox://styles/mapbox/light-v11"
            preserveDrawingBuffer
            projection={MAP_PROJECTION}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          >
            <NavigationControl showCompass={false} position="top-left" />

            {mapDataVisualisation}

            {renderMapTypeSelect()}
            {renderHeatMapLegend()}
            {renderMapLegend()}
          </MapGl>

          {isFilterVisible && (
            <FoundationYearFilter
              width={containerRef.current?.offsetWidth || 0}
              endYear={endYear}
              onChange={handleActiveYearChange}
              startYear={startYear}
              activeYear={Number(activeYear)}
              foundationsByYear={foundationsByYear}
            />
          )}
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </div>
  );
};
