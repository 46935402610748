import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Backdrop } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { ChatStatement } from 'components/Chat/ChatStatement';
import { DashboardFilterModal } from 'components/DashboardFilterModal';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardParams } from 'entities/DashboardParams.entity';
import {
  ChartUnitTypes,
  InsightsType,
  MessageTypes,
  queryKeys,
  Routes
} from 'enums';
import { useIsExternalUserDashboard } from 'hooks';
import {
  useCreatedNestedDashboard,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import { useChatById } from 'hooks/api/useChatById';
import millify from 'millify';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import {
  Cell,
  Pie,
  PieChart as PieChartComponent,
  ResponsiveContainer,
  Sector,
  Tooltip
} from 'recharts';
// eslint-disable-next-line import/no-unresolved
import { PieSectorDataItem } from 'recharts/types/polar/Pie';

import { SelectedFilterItem } from '../WidgetFilter';
import { PieChartTooltip } from './PieChartTooltip';

import styles from './styles.module.scss';

export const WARNING_COLORS = [
  '#fb8a00',
  '#ff9600',
  '#ffa51c',
  '#ffb648',
  '#ffcb7d',
  '#ffe0b0',
  '#fff3df'
];

export const SUCCESS_COLORS = [
  '#00be56',
  '#00ce61',
  '#00d878',
  '#12e192',
  '#7deab2',
  '#b5f2d0',
  '#e1faec'
];

export const PieChartThemeColors = Object.freeze({
  [ChartUnitTypes.MaterialsPie]: SUCCESS_COLORS,
  [ChartUnitTypes.TechnologiesPie]: WARNING_COLORS
});

const renderActiveShape =
  (activeIndex: number | undefined) => (props: PieSectorDataItem) => {
    const {
      cx = 0,
      cy = 0,
      innerRadius = 0,
      outerRadius = 0,
      startAngle,
      endAngle,
      fill
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          cornerRadius={0}
          endAngle={endAngle}
          fill={fill}
        />
        {activeIndex !== undefined && (
          <Sector
            cx={cx}
            cy={cy}
            cornerRadius={0}
            endAngle={endAngle}
            startAngle={startAngle}
            innerRadius={outerRadius - 35}
            outerRadius={outerRadius - 31}
            fill={fill}
          />
        )}
      </g>
    );
  };

interface Props {
  note?: string;
  title?: string;
  summary?: string;
  data?: DashboardParams;
  isTextWidgetsProcessing?: boolean;
}

const chartMeasurements = {
  full: {
    textY: 10,
    startAngle: -360,
    cy: '50%',
    innerRadius: 65,
    outerRadius: 95
  },
  half: {
    textY: -10,
    startAngle: 180,
    cy: '70%',
    innerRadius: 75,
    outerRadius: 105
  }
};

export const PieChart: FC<Props> = ({
  data,
  summary,
  title,
  note,
  isTextWidgetsProcessing
}) => {
  const { chatId, dashboardId, messageId } = useParams();
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<SelectedFilterItem>(null);
  const [isApplyFilterModalOpen, setApplyFilterModalOpen] = useState(false);
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>();
  const chartColor = data?.type
    ? // @ts-expect-error type error
      PieChartThemeColors[data?.type]
    : PieChartThemeColors.materials_pie;

  const selectItem = (data: SelectedFilterItem) => {
    setSelectedItem(data);
  };

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const { mutate: fetchChatData } = useChatById();
  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const mappedData = (data?.labels || []).map((label, index) => ({
    name: label,
    value: data?.values?.[index]
  }));

  const onSuccess = () => {
    if (dashboardData?.chatId) {
      fetchChatData({ chatId: dashboardData.chatId });
    }
  };

  const { mutate: createNestedDashboard, isPending } =
    useCreatedNestedDashboard(onSuccess);

  const showFilter = dashboardData?.revision && dashboardData.revision > 2;

  const onApplyFilter = (filterItem: { name: string; code: string }) => {
    createNestedDashboard({
      dashboardId: dashboardId || '',
      chatId: chatId || '',
      originDashboardId: dashboardData?.originDashboardId,
      data: {
        type: InsightsType.PieChart,
        value: filterItem?.code || ''
      }
    });
  };

  const totalAmount = useMemo(() => {
    const total = data?.values?.reduce((a, b) => {
      if (typeof a === 'number' && typeof b === 'number') {
        return a + b;
      }
      return 0;
    }, 0);

    return Math.floor(typeof total === 'number' ? total : 1);
  }, [data?.values]);

  const showHalfPieChart = (data?.values?.length || 0) < 4;
  const { cy, textY, startAngle, outerRadius, innerRadius } = showHalfPieChart
    ? chartMeasurements.half
    : chartMeasurements.full;

  const totalAmountFormatted = useMemo(() => {
    if (totalAmount.toString().length > 4) {
      return millify(totalAmount, {
        precision: 1,
        lowercase: true
      });
    }
    return totalAmount;
  }, [totalAmount]);

  const generalLabel = useMemo(() => {
    if (activeIndex === undefined) {
      return `${totalAmountFormatted}%`;
    }

    const highlightedItem = mappedData[activeIndex];
    return `${(typeof highlightedItem.value === 'number'
      ? highlightedItem.value
      : 1
    ).toFixed(1)}%`;
  }, [activeIndex, mappedData, totalAmountFormatted]);

  const onMouseEnter = (_data: unknown, index: number) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(undefined);
  };

  const handleRemoveTooltip = useCallback((event: MouseEvent) => {
    const isPieChartSector = (
      event?.target as SVGPathElement
    )?.classList?.contains('recharts-sector');

    if (!isPieChartSector) {
      setActiveIndex(undefined);
    }
  }, []);

  const hasCoins = userInfo?.isPremiumUser ? true : userCoins?.count;

  const sendMixpanelEvent = (mixpanelEvent: MixpanelEvents) => {
    mixpanel?.track(mixpanelEvent, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': `${location.origin}/chats/${chatId}/dashboards/${dashboardId}`
    });
  };

  const handleApplyFilterModalClose = () => {
    sendMixpanelEvent(MixpanelEvents.CancelFilterClick);

    setApplyFilterModalOpen(false);
  };

  const handleApplyFilterModalConfirm = () => {
    if (!hasCoins) {
      navigate(Routes.SubscriptionPlans, {
        state: { scrollToTop: true }
      });

      return;
    }

    sendMixpanelEvent(MixpanelEvents.ConfirmFilterClick);

    setApplyFilterModalOpen(false);

    if (selectedItem?.code) {
      onApplyFilter(selectedItem);
    }
  };

  const onClick = (data: {
    payload: { payload: { name: string; value: number } };
  }) => {
    if (!showFilter || isExternalUserDashboard) return null;

    const selected = data?.payload?.payload;
    const selectedItemData = { name: selected?.name, code: selected?.name };

    selectItem(selectedItemData);

    if (userInfo?.isFreeUser) {
      setApplyFilterModalOpen(true);
    } else {
      onApplyFilter(selectedItemData);
    }
  };

  useEffect(() => {
    document.body.addEventListener('mouseover', handleRemoveTooltip);

    return () => {
      document.body.removeEventListener('mouseover', handleRemoveTooltip);
    };
  }, [handleRemoveTooltip]);

  return (
    <div className={styles.container}>
      {isPending && (
        <Backdrop className={styles.overlay} open={isPending}>
          <div className={styles['overlay-content']}>
            <span className={styles.text}>{t('Common.NewDashboard')}</span>
            <div className={styles.spinner} />
          </div>
        </Backdrop>
      )}
      <DashboardFilterModal
        hasCoins={!!hasCoins}
        isOpen={isApplyFilterModalOpen}
        industry={selectedItem?.name || ''}
        onClose={handleApplyFilterModalClose}
        onConfirm={handleApplyFilterModalConfirm}
      />
      <div>
        <CommonTooltip
          className={cn(styles.title, 'overflowed-text-multiline')}
          title={title || t('Page.DashboardV2.PieChart.Title')}
        />
        <div className={styles.note}>
          <InfoIcon />

          {note}
        </div>
      </div>
      {mappedData?.length ? (
        <div className={styles['chart-container']}>
          <div className={styles['pie-chart-container']}>
            <ResponsiveContainer>
              <PieChartComponent className={styles['pie-chart']}>
                <Tooltip
                  wrapperStyle={{
                    display: activeIndex !== undefined ? 'block' : 'none'
                  }}
                  cursor={false}
                  position={{ y: -50 }}
                  content={<PieChartTooltip />}
                />
                <Pie
                  // eslint-disable-next-line react/no-unstable-nested-components
                  label={(props: { cx: number; cy: number }) => {
                    return (
                      <g>
                        <text
                          x={props.cx}
                          fill="#161616"
                          textAnchor="middle"
                          y={props.cy + textY}
                          className={styles['total-value']}
                        >
                          {generalLabel}
                        </text>
                      </g>
                    );
                  }}
                  cy={cy}
                  cx="50%"
                  radius={4}
                  endAngle={0}
                  startAngle={startAngle}
                  activeIndex={activeIndex}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  fill="#8884d8"
                  dataKey="value"
                  paddingAngle={1}
                  data={mappedData}
                  cornerRadius={0}
                  onClick={onClick}
                  className={styles.pie}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  activeShape={renderActiveShape(activeIndex)}
                >
                  {(mappedData || []).map((entry, index) => (
                    <Cell
                      strokeWidth={0}
                      key={`cell-${entry.name}`}
                      fill={
                        index < 7
                          ? chartColor[index % chartColor.length]
                          : chartColor[6]
                      }
                    />
                  ))}
                </Pie>
              </PieChartComponent>
            </ResponsiveContainer>
          </div>
          <div className={styles.rows}>
            {mappedData.map((row, index) => (
              <div key={row.name} className={styles.row}>
                <div className={cn(styles['label-container'])}>
                  <span
                    className={styles.color}
                    style={{
                      backgroundColor:
                        index < 7
                          ? chartColor[index % chartColor.length]
                          : chartColor[6]
                    }}
                  />
                  <span className={styles.label}>{row.name}</span>
                </div>
                <span className={styles.value}>{`${(typeof row.value ===
                'number'
                  ? row.value
                  : 1
                ).toFixed(1)}%`}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <WidgetEmptyState />
      )}
      {(isTextWidgetsProcessing || summary) && (
        <ChatStatement
          showAvatar={false}
          maxLettersAmount={700}
          isTextWidgetsProcessing={isTextWidgetsProcessing}
          data={{ type: MessageTypes.answer, statement: summary }}
        />
      )}
    </div>
  );
};
