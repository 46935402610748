import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { ReactComponent as RegenerateIcon } from 'assets/regenerate.svg';
import { ReactComponent as ThumbDownIcon } from 'assets/thumbs-down.svg';
import { ReactComponent as ThumbUpIcon } from 'assets/thumbs-up.svg';
import cn from 'classnames';
import { FeedbackModal, Tooltip } from 'components';
import { Message } from 'entities/Message.entity';
import { FeedbackTypes, Routes } from 'enums';
import { MessageTypes } from 'enums/MessageTypes.enum';
import { useRateChatMessage, useUserCoins, useUserInfo } from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { ChatStatement } from '../ChatStatement';
import { LimitedCompanyListNote } from '../LimitedCompanyListNote';

import styles from './styles.module.scss';

interface Props {
  data: Message;
  isLoading?: boolean;
  isLastAnswer?: boolean;
  isMessageSending?: boolean;
  isRateButtonHidden?: boolean;
  isDashboardButtonHidden?: boolean;
  isRegenerateMessageSending?: boolean;
  onRequestMoreDataClick?: () => void;
  onSuggestionClick?: (text: string) => void;
  regenerateChatMessage?: (messageId: string) => void;
  onShowDashboardClick?: (dashboardId: string, isPaid: boolean) => void;
}

export const ChatMessage: FC<Props> = ({
  data: {
    id,
    type,
    statement,
    hasFeedback,
    isPaid,
    isNotFull,
    messageId,
    suggestions,
    isInterrupted,
    dashboardId,
    hasUpgradeButton,
    isCompanyListLimited,
    requestMoreDataVisible = true
  },
  isLoading,
  isMessageSending,
  isLastAnswer,
  isRegenerateMessageSending,
  onShowDashboardClick,
  regenerateChatMessage,
  isDashboardButtonHidden,
  isRateButtonHidden,
  onSuggestionClick,
  onRequestMoreDataClick
}) => {
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  const [givenFeedback, setGivenFeedback] = useState<FeedbackTypes | null>(
    null
  );

  const { chatId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const {
    mutate: rateChatMessage,
    isPending: isRatePending,
    isSuccess: isRateSuccess
  } = useRateChatMessage(chatId || '', messageId || '');

  const hasError = id?.includes('error');

  const openFeedbackModal = (feedback: FeedbackTypes | null) => {
    if (
      !hasFeedback &&
      !isMessageSending &&
      !isRegenerateMessageSending &&
      !isRateSuccess &&
      !isRatePending
    ) {
      setGivenFeedback(feedback);
    }
  };

  const closeFeedbackModal = () => {
    if (!hasFeedback) {
      setGivenFeedback(null);
    }
  };

  const submitFeedback = (feedback: FeedbackTypes, feedbackText?: string) => {
    mixpanel?.track(MixpanelEvents.AnswerFeedback, {
      Feedback: feedback,
      'Feedback Text': feedbackText,
      'Chat ID': chatId,
      'Message ID': messageId,
      Text: statement
    });
    rateChatMessage({ text: feedbackText, feedbackType: feedback });
  };

  const handleShowDashboardClick = () => {
    if (onShowDashboardClick && dashboardId) {
      onShowDashboardClick(dashboardId, !!isPaid);
    }
  };

  const regenerateMessage = (messageId?: string) => () => {
    if (regenerateChatMessage && messageId) {
      regenerateChatMessage(messageId);
    }
  };

  const handleUpgradeToPremiumClick = () => {
    navigate(Routes.SubscriptionPlans, {
      state: { scrollToTop: true }
    });
  };

  const answerInProgress =
    (isMessageSending || isRegenerateMessageSending) && isLastAnswer;
  const isRateAvailable = !isRateSuccess && !isRatePending && !hasFeedback;
  const hideRateButton =
    answerInProgress || isRateButtonHidden || suggestions?.length;
  const showDashboardButtonVisible =
    !!dashboardId &&
    !isDashboardButtonHidden &&
    !answerInProgress &&
    !isNotFull;
  const restartGenerationButtonVisible =
    isLastAnswer &&
    !isMessageSending &&
    !isRegenerateMessageSending &&
    isNotFull;
  const disableDashboard =
    !isPaid &&
    (userInfo?.deactivatedAt || (userInfo?.isFreeUser && !userCoins?.count));
  const actionsVisible =
    (requestMoreDataVisible && type === MessageTypes.answer) ||
    showDashboardButtonVisible ||
    restartGenerationButtonVisible;

  const hasFewCompanies = type === MessageTypes.answer && isCompanyListLimited;

  return (
    <div
      className={cn(
        styles['message-wrapper'],
        hasFewCompanies && styles['companies-list-limited']
      )}
    >
      <div
        ref={messageContainerRef}
        data-testid="chat-message"
        className={cn(styles.message, styles[type])}
      >
        {!!givenFeedback && (
          <FeedbackModal
            feedback={givenFeedback}
            onClose={closeFeedbackModal}
            onSubmitFeedback={submitFeedback}
          />
        )}
        <ChatStatement
          data={{ type, statement }}
          showCaret={isLastAnswer && isLoading}
        >
          <>
            {isInterrupted &&
              (type === MessageTypes.answer ||
                type === MessageTypes.followup_questions) && (
                <span className={styles['interrupted-note']}>
                  {t('Page.Chat.InterruptedNote')}
                </span>
              )}

            {hasUpgradeButton && (
              <Button
                size="medium"
                color="primary"
                variant="contained"
                className={cn(styles.button, 'brilliance')}
                onClick={handleUpgradeToPremiumClick}
              >
                {t('Page.Chat.UpgradeToPremium')}
              </Button>
            )}

            {!!suggestions?.length && onSuggestionClick && (
              <div className={styles.suggestions}>
                {suggestions.map((suggestion) => (
                  <div
                    onClick={() => !isLoading && onSuggestionClick(suggestion)}
                    className={styles.suggestion}
                    key={suggestion}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}

            <div
              className={cn(
                styles.footer,
                !actionsVisible && styles['end-aligned']
              )}
            >
              {actionsVisible && (
                <div className={styles.actions}>
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    onClick={onRequestMoreDataClick}
                    className={cn(
                      styles['action-button'],
                      !showDashboardButtonVisible && 'brilliance'
                    )}
                  >
                    {t('Page.Chat.RequestAdditionalData')}
                  </Button>

                  {showDashboardButtonVisible && (
                    <Tooltip
                      title={
                        !userCoins?.count
                          ? t(
                              'Common.FreeSubscriptionFullDashboardButtonTooltip'
                            )
                          : t(
                              'Common.UnlockCoinsModal.DeactivatedAccountTooltip'
                            )
                      }
                      disabled={!disableDashboard}
                      className={styles.tooltip}
                    >
                      <span>
                        <Button
                          size="medium"
                          color="primary"
                          variant="contained"
                          disabled={!!disableDashboard}
                          className={cn(styles['action-button'], 'brilliance')}
                          onClick={handleShowDashboardClick}
                        >
                          {t(
                            !isPaid && userInfo?.isFreeUser
                              ? 'Page.Chat.ForDashboard'
                              : 'Page.Chat.ForPaidDashboard'
                          )}
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </div>
              )}

              <div className={styles['additional-actions']}>
                {restartGenerationButtonVisible && (
                  <div className={styles['regenerate-container']}>
                    <span className={styles.label}>
                      {t('Page.Chat.RestartGeneration')}
                    </span>
                    <IconButton
                      edge="end"
                      onClick={regenerateMessage(messageId)}
                    >
                      <RegenerateIcon className={styles.icon} />
                    </IconButton>
                  </div>
                )}

                {type === MessageTypes.answer &&
                  !hideRateButton &&
                  !hasError && (
                    <div className={styles['rate-container']}>
                      <span className={styles.label}>
                        {t('Page.Chat.RateAnswer')}
                      </span>
                      <Tooltip
                        title={t('Common.FeedbackModal.DisabledTooltip')}
                        disabled={!hasFeedback}
                        className={styles.tooltip}
                      >
                        <IconButton
                          edge="end"
                          className={cn(
                            styles['rate-container-button'],
                            !isRateAvailable && styles.disabled
                          )}
                          onClick={() =>
                            openFeedbackModal(FeedbackTypes.NEGATIVE)
                          }
                        >
                          <ThumbDownIcon className={styles.icon} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={t('Common.FeedbackModal.DisabledTooltip')}
                        disabled={!hasFeedback}
                        className={styles.tooltip}
                      >
                        <IconButton
                          edge="end"
                          className={cn(
                            styles['rate-container-button'],
                            !isRateAvailable && styles.disabled
                          )}
                          onClick={() =>
                            openFeedbackModal(FeedbackTypes.POSITIVE)
                          }
                        >
                          <ThumbUpIcon className={styles.icon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
              </div>
            </div>
          </>
        </ChatStatement>
      </div>

      {hasFewCompanies && <LimitedCompanyListNote />}
    </div>
  );
};
