import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal, SubTitle } from 'components';
import { Routes } from 'enums';
import { useUpdateUserInfo } from 'hooks/api/useUpdateUserInfo';
import { useUserInfo } from 'hooks/api/useUserInfo';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

export const NewUsersInformationModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const { mutate: updateUserInfo } = useUpdateUserInfo(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = (onSuccess?: () => void) => {
    setIsOpen(false);
    updateUserInfo({ isFirstLogin: false }, { onSuccess });

    mixpanel?.track(MixpanelEvents.AccountCreated, {
      'User ID': userInfo?.id,
      Email: userInfo?.email,
      'Date/Time of Account Creation': userInfo?.createdAt,
      'Plan Type': 'Free'
    });
  };

  const handleCloseModalButtonClick = () => onClose();

  const handleUpgradeButtonClick = () => {
    onClose(() => {
      navigate(Routes.SubscriptionPlans);
    });
  };

  useEffect(() => {
    setIsOpen(
      Boolean(!userInfo?.isBeta && userInfo?.isFirstLogin && userInfo?.lastName)
    );
  }, [userInfo]);

  return (
    <Modal
      title={t('Common.NewUsersInformationModal.Title')}
      titleClassName={styles.title}
      className={styles.container}
      isOpen={isOpen}
    >
      <SubTitle className={styles.subtitle}>
        {t('Common.NewUsersInformationModal.Subtitle')}
      </SubTitle>

      <div className={styles.buttons}>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="contained"
          className={cn(styles.button, 'brilliance')}
          onClick={handleUpgradeButtonClick}
        >
          {t('Common.NewUsersInformationModal.UpgradeButton')}
        </Button>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="outlined"
          onClick={handleCloseModalButtonClick}
          className={cn(styles.button)}
        >
          {t('Common.NewUsersInformationModal.ContinueButton')}
        </Button>
      </div>
    </Modal>
  );
};
